import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import { NavLink } from "reactstrap";
import contact from "./components/Contact";
import Header from "./components/nav";
import Team from "./components/teams";
import Home from "./components/Home";
import "./css/App.css";
import Week_code from "./components/weekOfCode";
import logo from "./images/logo.png";
import {
  MdEmail,
  MdLocalPhone,
  MdMap,
  MdCode,
  MdSubscriptions,
} from "react-icons/md";
import { FaTwitter, FaSlackHash, FaLinkedin } from "react-icons/fa";

const App = () => {
  return (
    <Router>
      <div className="App">
        {/*navbar*/}
        <Header />
        <Route exact path="/contact" component={contact} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/week_code" component={Week_code} />
        <Route exact path="/" component={Home} />
      </div>
      <footer className="footer">
        <Row>
          {/*logo */}
          <Col>
            <Image
              src={logo}
              style={{ paddingTop: "2rem", paddingBottom: 15 }}
              rounded
            />
            <h5 style={{ paddingTop: "1rem" }}>
              <FaLinkedin /> <FaTwitter /> <FaSlackHash />
            </h5>
          </Col>
          {/*contact us */}
          <Col className="d-none d-md-block">
            <h5 style={{ fontWeight: "bold", paddingTop: "2rem" }}>
              Contact us <MdCode />
            </h5>
            <Row>
              <Col>
                <h6 style={{ paddingTop: "1rem" }}>
                  <MdEmail /> ccifisat2018@gmail.com
                </h6>
                <h6 style={{ paddingTop: "1rem" }}>
                  <MdLocalPhone /> +91 94967 18067
                </h6>
                <h6 style={{ textAlign: "center", paddingTop: "1rem" }}>
                  <MdMap /> Hormis Nagar, Mookkannoor,Kerala
                </h6>
              </Col>
            </Row>
          </Col>
          {/*links*/}
          <Col className="d-none d-md-block" style={{ paddingTop: "2rem" }}>
            <h5 style={{ fontWeight: "bold" }}>
              Links <MdCode />
            </h5>
            <NavLink style={{ color: "white" }} href="/">
              Home
            </NavLink>
            <NavLink style={{ color: "white" }} href="/team">
              Team
            </NavLink>
            <NavLink style={{ color: "white" }} href="/contact">
              Contact Us
            </NavLink>
          </Col>
          {/*sign up for updates*/}
          <Col className="d-none d-md-block">
            <h5 style={{ fontWeight: "bold", paddingTop: "2rem" }}>
              Sign up for updates <MdSubscriptions />
            </h5>
            <Form style={{ paddingTop: 20 }}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
            </Form>
            <Button style={{ fontWeight: "bold" }} variant="secondary">
              Subscribe
            </Button>
          </Col>
        </Row>
      </footer>
      <footer className="foot">
        <Row>
          <Col>
            <h6 style={{ color: "#6e6e78" }}>© 2021 CCI, FISAT</h6>
          </Col>
        </Row>
      </footer>
    </Router>
  );
};
export default App;
