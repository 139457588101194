import React from "react";
import { Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import assets from "../images/contact.svg";
import asset from "../images/Chat.png";
import "../css/style.css";

class contact extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["username"] = "";
      fields["emailid"] = "";
      fields["mobileno"] = "";
      fields["message"] = "";
      this.setState({ fields: fields });
      alert("Form submitted");
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "*Please enter your username.";
    }

    if (typeof fields["username"] !== "undefined") {
      if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["username"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["emailid"]) {
      formIsValid = false;
      errors["emailid"] = "*Please enter your email-ID.";
    }

    if (typeof fields["emailid"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["emailid"])) {
        formIsValid = false;
        errors["emailid"] = "*Please enter valid email-ID.";
      }
    }

    if (!fields["mobileno"]) {
      formIsValid = false;
      errors["mobileno"] = "*Please enter your mobile no.";
    }

    if (typeof fields["mobileno"] !== "undefined") {
      if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["mobileno"] = "*Please enter valid mobile no.";
      }
    }

    if (!fields["message"]) {
      formIsValid = false;
      errors["message"] = "*Please enter your feedback";
    }

    if (typeof fields["message"] !== "undefined") {
      if (!fields["message"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  launch_toast() {
    var x = document.getElementById("toast");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 5000);
  }

  render() {
    return (
      <div
        style={{ padding: "5rem 0 5rem 0" }}
        className="d-flex justify-content-center align-items-center flex-wrap"
      >
        <Image
          //   className="img-fluid"
          style={{ width: "50%", paddingRight: "5rem" }}
          src={assets}
          alt=" assests"
          rounded
        />
        <div>
          <Image src={asset} rounded />
          <form
            className="d-flex justify-content-center"
            action=""
            id="myform"
            onsubmit="return validate();"
          >
            <div style={{ paddingTop: 20 }} id="main-registration-container">
              <form
                method="post"
                style={{ paddingTop: 30 }}
                name="userRegistrationForm"
                onSubmit={this.submituserRegistrationForm}
              >
                <input
                  type="text"
                  style={{
                    fontSize: 18,
                    width: "15rem",
                    boxShadow: "1px 1px 1px #9E9E9E",
                  }}
                  name="username"
                  value={this.state.fields.username}
                  onChange={this.handleChange}
                  placeholder="Name"
                />
                <div className="errorMsg">{this.state.errors.username}</div>
                <div style={{ paddingTop: 20 }}></div>
                <input
                  type="text"
                  name="emailid"
                  style={{
                    fontSize: 18,
                    width: "15rem",
                    boxShadow: "1px 1px 1px #9E9E9E",
                  }}
                  value={this.state.fields.emailid}
                  onChange={this.handleChange}
                  placeholder="email"
                />
                <div className="errorMsg">{this.state.errors.emailid}</div>
                <div style={{ paddingTop: 20 }}></div>
                <input
                  type="text"
                  name="mobileno"
                  style={{
                    fontSize: 18,
                    width: "15rem",
                    boxShadow: "1px 1px 1px #9E9E9E",
                  }}
                  value={this.state.fields.mobileno}
                  onChange={this.handleChange}
                  placeholder="Phone"
                />
                <div className="errorMsg">{this.state.errors.mobileno}</div>
                <div style={{ paddingTop: 20 }}></div>
                <input
                  onclick="launch_toast()"
                  type="text"
                  name="message"
                  style={{
                    fontSize: 18,
                    width: "15rem",
                    height: "5rem",
                    boxShadow: "1px 1px 1px #9E9E9E",
                  }}
                  value={this.state.fields.message}
                  onChange={this.handleChange}
                  placeholder="Feedback"
                />
                <div className="errorMsg">{this.state.errors.message}</div>

                <div style={{ paddingTop: 15 }}></div>
                <div className="d-flex justify-content-center">
                  <input
                    style={{ width: "8em" }}
                    type="submit"
                    className="button"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default contact;
