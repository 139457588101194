import React from "react";
import { Card, Container, Image, Carousel } from "react-bootstrap";
import { FaUserCheck, FaAndroid } from "react-icons/fa";
import { MdWeb } from "react-icons/md";
import head from "../images/shape-1.png";
import kerala from "../images/kerala.png";
import fisat from "../images/fisat.png";
import creative from "../images/creative.png";
import gxtron from "../images/gxtron.webp";
import fisat1 from "../images/fisatian.png";
import petsday from "../images/petsday.png";
import canteen from "../images/canteen.png";
import mokkanoor from "../images/mokkanoor.png";

const Home = () => {
  return (
    <div>
      {/*----------------------------landing title and landing image------------------------------*/}
      <div
        className="d-flex align-items-center justify-content-center flex-wrap"
        style={{
          backgroundColor: "#f7f7f7",
          width: "auto",
          height: "auto",
          marginTop: "4rem",
        }}
      >
        {/*----------------------------------------Landing Title-------------------------------*/}
        <div style={{ marginLeft: "2rem" }}>
          <h1
            style={{
              fontSize: "6rem",
              fontWeight: "bold",
            }}
          >
            We Make
          </h1>
          <h1
            style={{
              fontSize: "5rem",
              fontWeight: "bold",
              color: "#0f64bd",
            }}
          >
            Things Possible
          </h1>
        </div>
        {/*----------------------------Landing Image------------------------------------*/}
        <div className="d-flex justify-content-end">
          <Image
            className="img-fluid"
            src={head}
            rounded
            alt="head"
            style={{ width: "80%" }}
          />
        </div>
      </div>
      {/*-----------------------------------our best service heading---------------------------*/}
      <div>
        <h2
          className="d-flex justify-content-center"
          style={{ fontWeight: "bold", paddingTop: "3rem" }}
        >
          Our Best Services
        </h2>
      </div>
      {/*-----------------------------------------services----------------------------------------*/}
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ paddingBottom: "2rem" }}
      >
        {/*-----------------------------UI/UX----------------------------------*/}
        <div
          className="border"
          style={{
            minHeight: "10rem",
            width: "18rem",
            margin: "1rem 2rem 1rem 2rem ",
          }}
        >
          <div style={{ margin: "auto", marginTop: "1rem" }}>
            <h1 className="d-flex justify-content-center">
              <FaUserCheck style={{ color: "blue" }} />
            </h1>
          </div>
          <div style={{ margin: "3rem 0 0 0" }}>
            <div
              className="d-flex justify-content-center"
              style={{ fontWeight: "bold" }}
            >
              <h5
                className="d-flex justify-content-center"
                style={{ fontWeight: "bold" }}
              >
                UI / UX
              </h5>
            </div>

            <div
              className="text-justify"
              style={{ color: "#6e6c69", padding: "0 1rem 1rem 1rem" }}
            >
              User experience design (UXD, UED, or XD) is the process of
              manipulating user behavior through usability, usefulness, and
              desirability provided in the interaction with a product.
            </div>
          </div>
        </div>

        {/*----------------------------Android Developement--------------------------------*/}
        <div
          className="border"
          style={{
            minHeight: "10rem",
            width: "18rem",
            margin: "1rem 2rem 1rem 2rem ",
          }}
        >
          <div style={{ margin: "auto", marginTop: "1rem" }}>
            <h1 className="d-flex justify-content-center">
              <FaAndroid style={{ color: "blue" }} />
            </h1>
          </div>
          <div style={{ margin: "3rem 0 0 0" }}>
            <div
              className="d-flex justify-content-center"
              style={{ fontWeight: "bold" }}
            >
              <h5
                className="d-flex justify-content-center"
                style={{ fontWeight: "bold" }}
              >
                Android Development
              </h5>
            </div>

            <div
              className="text-justify"
              style={{ color: "#6e6c69", padding: "0 1rem 1rem 1rem" }}
            >
              Android software development is the process by which new
              applications are created for devices running the Android operating
              system. Android is first in the market
            </div>
          </div>
        </div>

        {/*--------------------------web developement----------------------------*/}
        <div
          className="border"
          style={{
            minHeight: "10rem",
            width: "18rem",
            margin: "1rem 2rem 1rem 2rem ",
          }}
        >
          <div style={{ margin: "auto", marginTop: "1rem" }}>
            <h1 className="d-flex justify-content-center">
              <MdWeb style={{ color: "blue" }} />
            </h1>
          </div>
          <div style={{ margin: "3rem 0 0 0" }}>
            <div
              className="d-flex justify-content-center"
              style={{ fontWeight: "bold" }}
            >
              <h5
                className="d-flex justify-content-center"
                style={{ fontWeight: "bold" }}
              >
                Web Development
              </h5>
            </div>

            <div
              className="text-justify"
              style={{ color: "#6e6c69", padding: "0 1rem 1rem 1rem" }}
            >
              Web development is the work involved in developing a website for
              the Internet or an intranet.
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#f0f2f0" }}>
        {/*----------------------------------our success stories-----------------------------*/}
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "2rem" }}
        >
          <h2 style={{ fontWeight: "bold" }}> Our Success Stories</h2>
        </div>
        {/*-----------------------------carousel----------------------------------------------*/}
        <div>
          <Container xs={12} style={{ paddingTop: 40, paddingBottom: 40 }}>
            <Carousel style={{ color: "black" }}>
              <Carousel.Item>
                <Card style={{ width: "auto", margin: "auto" }}>
                  <Card.Img variant="top" src={fisat1} />
                  <Card.Body style={{ height: "14rem" }}>
                    <Card.Title style={{ paddingTop: 20, fontWeight: "bold" }}>
                      Fisatian(Phase 1)
                    </Card.Title>
                    <Card.Text style={{ paddingTop: 20 }}>
                      It is a college management system for FISAT college.This
                      application uses Django as backend and Progressive Web
                      App(PWA) as frontend created using HTML5+CSS3+JS.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item>
                <Card style={{ width: "auto", margin: "auto" }}>
                  <Card.Img variant="top" src={petsday} />
                  <Card.Body style={{ height: "14rem" }}>
                    <Card.Title style={{ paddingTop: 20, fontWeight: "bold" }}>
                      PetsDay Website Redesign:
                    </Card.Title>
                    <Card.Text style={{ paddingTop: 20 }}>
                      Petsday is an online platform to buy and sell pets. We
                      provided a new design for their online site. The redesign
                      was done using figma.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item>
                <Card style={{ width: "auto", margin: "auto" }}>
                  <Card.Img
                    variant="top"
                    style={{ maxHeight: "50rem" }}
                    src={canteen}
                  />
                  <Card.Body style={{ height: "14rem" }}>
                    <Card.Title style={{ paddingTop: 20, fontWeight: "bold" }}>
                      Canteen management system:
                    </Card.Title>
                    <Card.Text style={{ paddingTop: 20 }}>
                      This system is used to digitize the college canteen.
                      <br />
                      <p>
                        It can be used to • Preordering of food • Tabs on user
                        payments • Auto Payment for the staff
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item>
                <Card style={{ width: "auto", margin: "auto" }}>
                  <Card.Img
                    variant="top"
                    style={{ maxHeight: "50rem" }}
                    src={mokkanoor}
                  />
                  <Card.Body style={{ height: "14rem" }}>
                    <Card.Title style={{ paddingTop: 20, fontWeight: "bold" }}>
                      Mookkannoor Gramapanchayath app:
                    </Card.Title>
                    <Card.Text style={{ paddingTop: 20 }}>
                      An information app for the Mookkannoor Gramapanchayath. It
                      also has an option to submit feedback and report about
                      street lights which are not working.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </Container>
        </div>
      </div>
      <div>
        {/*-----------------------------our clients-------------------*/}
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "2rem" }}
        >
          <h2 style={{ fontWeight: "bold" }}> Our Clients</h2>
        </div>
        <div
          className="d-flex justify-content-center flex-wrap"
          style={{ padding: "2rem 0 2rem 0" }}
        >
          <Image
            style={{ padding: "0rem 3rem 0rem 3rem" }}
            src={kerala}
            rounded
          />
          <Image
            style={{ padding: "0rem 3rem 0rem 3rem" }}
            src={fisat}
            rounded
          />
          <Image
            style={{ padding: "0rem 3rem 0rem 3rem" }}
            src={creative}
            rounded
          />
          <Image
            style={{ padding: "0rem 3rem 0rem 3rem" }}
            src={gxtron}
            rounded
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
