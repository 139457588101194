import React from "react";
import teams from "../json/teams.json";
import { FaLinkedin, FaGithub, FaTwitter } from "react-icons/fa";
import asset from "../images/our_team.png";
import Gallery from "../images/gallery.png";
import { Image, Col, Row } from "react-bootstrap";


export default function Team() {
  let keys=[]
  function Visibility(items){
    if(items.twitter==="NULL"){
      keys.push(items.id)
    }
  }
  function Show(items){
    if(keys.includes(items.id)){
      return true;
    }
  }
  console.log(keys);
  return (
    <div style={teamStyle}>
      <Row>
        <Col sm={4} lg={1} md={8} style={{ paddingLeft: "3rem" }}>
          <Image style={{ paddingTop: 20 }} src={asset} rounded />
        </Col>
      </Row>
      <div className="d-flex flex-wrap justify-content-center m-4 m-2">
        {teams.map((item) => (
          <div key={item.id} className="m-4" style={{ width: "18rem" }}>
            <div className="border" style={{ backgroundColor: "#ffff" }}>
              <div className="px-auto d-flex justify-content-center pt-4 pb-2">
                <Image
                  src={process.env.PUBLIC_URL + item.image}
                  roundedCircle
                  alt="Individual pictures"
                />
              </div>
              <div
                className="px-auto d-flex justify-content-center"
                style={{ color: "#45ad97", fontWeight: "bold" }}
              >
                {item.name}
              </div>
              <div className="d-flex flex-row justify-content-center pb-2">
                <a href={item.linkedln}>
                  <FaLinkedin
                    className="w-100 px-3"
                    style={{ color: "black" }}
                  />
                </a>
                <a check={Visibility(item)} style={{visibility : Show(item) ? 'hidden' : 'visible'}} href={item.twitter}>
                  <FaTwitter
                    className="w-100 px-3"
                    style={{ color: "black" }}
                  />
                </a>
                
                <a href={item.gitlab}>
                  <FaGithub className="w-100 px-3" style={{ color: "black" }} />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ backgroundColor: "white" }}>
        <br />
        <Row className="justify-content-md-center">
          <Col className="d-block d-sm-block d-md-none">
            <Image src={Gallery} style={{ paddingLeft: "6rem" }} rounded />
          </Col>

          <Col className="d-none d-lg-block" xs={12} md={1}>
            <Image src={Gallery} rounded />
          </Col>
          <div className="gallery" style={{ paddingBottom: 10 }}>
            <div className="gallery-item-one"></div>
            <div className="gallery-item-two"></div>
            <div className="gallery-item-three"></div>
            <div className="gallery-item-four"></div>
            <div className="gallery-item-five"></div>
            <div className="gallery-item-six"></div>
          </div>
        </Row>
      </div>
    </div>
  );
}
const teamStyle = {
  paddingTop: 70,
  backgroundColor: "#ebebeb",
  paddingBottom: 20,
};
