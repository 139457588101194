import React from "react";
import { Card, Image, Alert, Button, ListGroup } from "react-bootstrap";
import Landing from "../images/code.png";
import "../css/week.css";
import html from "../images//html.png";
import css from "../images/css.png";
import js from "../images/js.png";
import week from "../images/week.png";

const weekOfCode = () => {
  return (
    <div>
      {/* --------------------------------landing image and text --------------------------------*/}
      <div
        className="test"
        style={{ margin: "3rem 0 3rem 0", paddingBottom: "2rem" }}
      >
        {/* -------------------------------- landing image --------------------------------*/}
        <div
          style={{ padding: "5rem 1rem 5rem 1rem" }}
          className="d-flex justify-content-center flex-wrap "
        >
          <img
            style={{ width: "30%" }}
            src={Landing}
            className="img-fluid mx-auto img-fluid d-none d-md-block"
            alt=""
          />
          {/*  --------------------------------landing text --------------------------------*/}
          <div className="d-flex align-items-center">
            <h1
              style={{
                letterSpacing: "1rem",
                color: "white",
                fontSize: "5rem",
                paddingTop: "4rem",
                fontWeight: "bold",
              }}
            >
              WEEK OF CODING
            </h1>
          </div>
        </div>
        {/* --------------------------------register button -------------------------------- */}
        <div className="d-flex justify-content-center">
          <Button variant="success" style={{ fontSize: "2rem" }}>
            <b>Closed</b>
          </Button>
        </div>
      </div>
      {/* --------------------------------event discription  -------------------------------- */}

      <div style={{ margin: "3rem 0 3rem 0" }}>
        {/*  --------------------------------discription heading --------------------------------*/}
        <h1 className="d-flex justify-content-center">
          <b>| About The Event </b>
        </h1>
        {/* -------------------------------- discription text --------------------------------*/}
        <div className="d-flex justify-content-center flex-wrap eventAbout">
          <Alert variant="light">
            <Alert.Heading
              className="text-justify"
              style={{
                color: "black",
                fontSize: "2rem",
                paddingTop: 30,
              }}
            >
              Let us spend the time off from college in a creative and fruitful
              manner.
            </Alert.Heading>
            {/*-------------------------------------text----------------------------------------------- */}
            <h4 style={{ paddingTop: 20 }}>
              "The Week of Code, organized by Centre for Cyber Innovations in
              Association with FISAT, is a free to attend online workshop for
              design of dynamic and interactive web pages."
            </h4>
            <hr />
          </Alert>
        </div>
      </div>
      {/*  --------------------------------event domain --------------------------------*/}
      <div style={{ margin: "3rem 0 3rem 0" }}>
        {/*  --------------------------------heading --------------------------------*/}
        <h1 className="d-flex justify-content-center">
          <b>Domains</b>
        </h1>
        {/* --------------------------------cards  --------------------------------*/}
        <div
          style={{ paddingTop: "2rem" }}
          className="d-flex justify-content-center flex-wrap"
        >
          {/*-------------------------------------card 1------------------------------------*/}
          <div
            className="border text-justify"
            style={{
              width: "25rem",
              margin: "1rem 2rem 1rem 2rem",
              minHeight: "15rem",
              padding: "1rem",
            }}
          >
            <h1 className="d-flex justify-content-center">
              <Image src={html} rounded />
            </h1>
            <div className="d-flex justify-content-center">
              <h3 style={{ fontWeight: "bold", paddingLeft: "auto" }}>HTML</h3>
            </div>
            <div>
              Hypertext Markup Language is the standard markup language for
              documents designed to be displayed in a web browser.
            </div>
          </div>
          {/*---------------------------card2---------------------------------------*/}
          <div
            className="border text-justify"
            style={{
              width: "25rem",
              margin: "1rem 2rem 1rem 2rem",
              minHeight: "15rem",
              padding: "1rem",
            }}
          >
            <h1 className="d-flex justify-content-center">
              <Image src={js} rounded />
            </h1>
            <div className="d-flex justify-content-center">
              <h3 style={{ fontWeight: "bold", paddingLeft: "auto" }}>
                Java Script
              </h3>
            </div>
            <div>
            JavaScript often abbreviated as JS, is a programming language that conforms to the ECMAScript specification.
            </div>
          </div>
          <div
            className="border text-justify"
            style={{
              width: "25rem",
              margin: "1rem 2rem 1rem 2rem",
              minHeight: "15rem",
              padding: "1rem",
            }}
          >
            <h1 className="d-flex justify-content-center">
              <Image src={css} rounded />
            </h1>
            <div className="d-flex justify-content-center">
              <h3 style={{ fontWeight: "bold", paddingLeft: "auto" }}>CSS</h3>
            </div>
            <div>
              Cascading Style Sheets is a style sheet language used for
              describing the presentation of a document written in a markup
              language like HTML.
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------- what to expect --------------------------------*/}
      <div style={{ margin: "3rem 0 3rem 0" }}>
        <h1 className="d-flex justify-content-center">
          <b>What to Expect</b>
        </h1>
        {/*  --------------------------------image --------------------------------*/}
        <div className="d-flex justify-content-center">
          <Image style={{ width: "35%" }} src={week} rounded />
        </div>
      </div>
      {/*  --------------------------------access the project --------------------------------*/}
      <div style={{ margin: "3rem 0 3rem 0" }}>
        <h1 style={{ textAlign: "center" }}>
          <b>Access the Project</b>
        </h1>
        {/* -------------------------------- contents --------------------------------*/}
        <div className="d-flex justify-content-center flex-wrap">
          {/* -------------------------------- github --------------------------------*/}
          <div
            style={{
              padding: "2rem 2rem 2rem 2rem",
              margin: "1rem 2rem 1rem 2rem",
              maxWidth: "30rem",
            }}
            className="alert alert-success"
            role="alert"
          >
            <div>
              <h2>GitHub</h2>
            </div>
            <p>
              We are using GitHub for providing the resources, examples and
              Assignment. We will be provide contents for each day via GitHub.
            </p>
            <hr />
            <p>
              <a href="https://github.com/CCIFISAT/week_of_coding">
                <button
                  type="button"
                  className="btn btn-outline-success and-all-other-classes"
                >
                  Click
                </button>
              </a>
            </p>
          </div>
          {/*  --------------------------------git cheat sheet --------------------------------*/}
          <div
            style={{
              padding: "2rem 2rem 2rem 2rem",
              margin: "1rem 2rem 1rem 2rem",
              maxWidth: "30rem",
            }}
            className="alert alert-success"
            role="alert"
          >
            <div>
              <h2>Git Cheat Sheet</h2>
            </div>
            <p>
              Git cheat sheet that serves as a quick reference for basic Git
              commands to help you learn Git. Git branches, remote repositories,
              , and more.
            </p>
            <hr />
            <p>
              <a href="https://education.github.com/git-cheat-sheet-education.pdf">
                <button
                  type="button"
                  className="btn btn-outline-success and-all-other-classes"
                >
                  Click
                </button>
              </a>
            </p>
          </div>
        </div>
      </div>
      {/*  --------------------------------things to notice --------------------------------*/}
      <div style={{ margin: "3rem 0 3rem 0" }}>
        <h1 style={{ textAlign: "center" }}>
          <b>Things to Notice</b>
        </h1>
        {/* -------------------------------- cards --------------------------------*/}
        <div>
          <Card style={{ width: "28rem" }}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <b>1. Contents and Assignment will be released each day.</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>2. Clone every day project before you start learning. </b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  3. You can run the examples on any editor eg (visual Studio,
                  text editor etc) on your PC
                </b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  4. Go through every examples and contents that make it easier
                  to submit daily assignment
                </b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>5. A detailed video is provided about how to use Git</b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  6. While submitting assignment create your own branch in CCI
                  repo,  and use your name as branch name.
                </b>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  7. Submit your assignment before evening. Successful
                  submissions will be merged.
                </b>{" "}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>
                  8. Don't worry if you are not able to submit the assignment.
                  Solution for each day Assignment will be available in Github
                  by evening.
                </b>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      </div>
      {/* -------------------------------- getting started with git --------------------------------*/}
      <div style={{ margin: "3rem 0 3rem 0" }}>
        <h1 style={{ textAlign: "center" }}>
          <b>Getting Started With Git</b>
        </h1>
        {/* --------------------------------iframes -------------------------------- */}
        <div>
          {/* --------------------------------iframe 1 -------------------------------- */}
          <div
            style={{ margin: "3rem 0 3rem 0" }}
            className="d-flex justify-content-center align-contents-center"
          >
            <iframe
              width="580"
              height="315"
              src="https://www.youtube.com/embed/zMgrv4PFt3M"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="youtube"
            ></iframe>
          </div>
          {/*  --------------------------------iframe 2 --------------------------------*/}
          <div
            style={{ margin: "3rem 0 3rem 0" }}
            className="d-flex justify-content-center align-contents-center"
          >
            <iframe
              width="580"
              height="315"
              src="https://www.youtube.com/embed/jQiSOmQrq-A"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="youtube"
            ></iframe>
          </div>
        </div>
      </div>
      {/*  --------------------------------contact info --------------------------------*/}
      {/* <div style={{ margin: "3rem 0 3rem 0" }}>
        {" "}
        <h1 className="d-flex justify-content-center">
          <b>| Contact Us</b>
        </h1>
        <div className="d-flex justify-content-center">
          <div style={{ color: "#001987", margin: "0 1rem 0 1rem" }}>
            <h1>__________</h1>
          </div>
          <div style={{ color: "#001987", margin: "0 1rem 0 1rem" }}>
            <h1>__________</h1>
          </div>
          <div style={{ color: "#001987", margin: "0 1rem 0 1rem" }}>
            <h1>__________</h1>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div style={{ margin: "0 1rem 0 1rem" }}>
            <h3>
              <b>Paul Elias Sojan</b>
            </h3>
            <h5 style={{ paddingTop: 10 }}>8281895254</h5>
            <h6>paul.eliassojan@gmail.com</h6>
          </div>
          <div style={{ margin: "0 1rem 0 1rem" }}>
            {" "}
            <h3>
              <b>Mohit Rajan E</b>
            </h3>
            <h5 style={{ paddingTop: 10 }}>9037969499</h5>
            <h6>mhit98@gmail.com</h6>
          </div>
          <div style={{ margin: "0 1rem 0 1rem" }}>
            <h3>
              <b>Devu R Kurup</b>
            </h3>
            <h6 style={{ paddingTop: 10 }}>9526701774</h6>
            <h6>devukurup199918@gmail.com</h6>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default weekOfCode;
