import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../images/ccilogo.png";
const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ width: "auto" }}>
      <Navbar fixed="top" color="light" light expand="md">
        <NavbarBrand
          className=" d-none d-xl-block"
          style={{ paddingLeft: 10 }}
          href="/"
        >
          <img
            src={logo}
            width="24"
            height="28"
            className="d-inline-block align-top"
            alt="logo"
          />{" "}
          CENTRE FOR <span>CYBER INNOVATION</span>
        </NavbarBrand>
        <NavbarBrand className="d-xl-none" style={{ paddingLeft: 10 }} href="/">
          <img
            src={logo}
            width="24"
            height="28"
            className="d-inline-block align-top"
            alt="logo"
          />{" "}
          <span
            style={{ fontWeight: "bold" }}
            className="w-responsive text-center mx-auto p-1 mt-2"
          >
            CCI
          </span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem style={{ padding: "0.5px" }}>
              <NavLink style={{ fontWeight: "bold" }} href="/">
                Home
              </NavLink>
            </NavItem>
            {/* <NavItem style={{padding: "0.5px"}}>
              <NavLink style={{fontWeight:'bold'}} href="/about">About</NavLink>
            </NavItem> */}
            <NavItem style={{ padding: "0.5px" }}>
              <NavLink style={{ fontWeight: "bold" }} href="/team">
                Team
              </NavLink>
            </NavItem>
            <NavItem style={{ padding: "0.5px" }}>
              <NavLink style={{ fontWeight: "bold" }} href="/contact">
                Contact Us
              </NavLink>
            </NavItem>
            <NavDropdown
              style={{ fontWeight: "bold" }}
              title="Events"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/week_code">
                Week Of Coding
              </NavDropdown.Item>
            </NavDropdown>
            {/* <NavItem style={{ padding: "0.5px" }}>
              <Button variant="outline-info" href="/hire">
                <b>Hiring!</b>
              </Button>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Example;
