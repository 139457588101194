import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics

// Add the Firebase products that you want to use
ReactDOM.render(<App />, document.getElementById("root"));
const firebaseConfig = {
  apiKey: "AIzaSyDNp4tRqKAm_O6qLWrGL5-Xt3DKbnaBuUw",
  authDomain: "cci-fisat.firebaseapp.com",
  databaseURL: "https://cci-fisat.firebaseio.com",
  projectId: "cci-fisat",
  storageBucket: "cci-fisat.appspot.com",
  messagingSenderId: "382715587433",
  appId: "1:382715587433:web:7a8de2092b72d86cdc2bf8",
  measurementId: "G-MSFD198QKJ",
};
firebase.initializeApp(firebaseConfig);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
